// ** React Imports
import { useState } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Block from "components/layout/Block";

import ShowHidePasswordButton from "containers/Landing/components/ShowHidePasswordButton";

// ** Login Components
import ErrorMessage from "containers/Landing/components/ErrorMessage";

// ** Styled Components
import { StyledAuthPanelInputContainer } from "containers/Landing/styles/AuthPanelInputContainer.styled";
import { StyledAuthPanelInput } from "containers/Landing/styles/AuthPanelInput.styled";

// #####################################################

const PasswordInput = ({
	password,
	setPassword,
	passwordError,
	setPasswordError,
	setUsernameError,
}) => {
	const { t } = useTranslation(["landing"]);
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	// #####################################################

	return (
		<Block mb={2}>
			<StyledAuthPanelInputContainer>
				<StyledAuthPanelInput
					type={passwordVisibility ? "text" : "password"}
					id="password"
					name="password"
					label="password"
					autoComplete="current-password"
					value={password}
					onChange={(event) => {
						setPassword(event.target.value);
					}}
					onBlur={(event) => {
						if (event.target.value === "") {
							setPasswordError(true);
						}
					}}
					onFocus={() => {
						setPasswordError(false);
						setUsernameError(false);
					}}
					active={Boolean(password)}
					placeholder={t("landing:login.passwordPlaceholder")}
					register
				/>
				{password && password.length > 0 && (
					<ShowHidePasswordButton
						passwordVisibility={passwordVisibility}
						setPasswordVisibility={setPasswordVisibility}
					/>
				)}
			</StyledAuthPanelInputContainer>
			{passwordError && (
				<ErrorMessage>
					{t("landing:login.passwordEmptyError")}
				</ErrorMessage>
			)}
		</Block>
	);
};

// #####################################################

export default PasswordInput;

// #####################################################
