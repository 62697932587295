// ** React Imports
import { useMemo } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Custom Components
import Block from "components/layout/Block";

// ** Login Components
import ErrorMessage from "containers/Landing/components/ErrorMessage";

// ** Styled Components
import { StyledAuthPanelInputContainer } from "containers/Landing/styles/AuthPanelInputContainer.styled";
import { StyledAuthPanelInput } from "containers/Landing/styles/AuthPanelInput.styled";

// #####################################################

const UsernameInput = ({
	username,
	setUsername,
	usernameError,
	setUsernameError,
	setPasswordError,
}) => {
	const { t } = useTranslation(["landing"]);

	const language = useSelector((state) => state.global.language);

	const placeholderText = useMemo(() => {
		const text = t("landing:login.usernamePlaceholder");
		return text;
	}, [language]);

	// #####################################################

	return (
		<Block mb={2}>
			<StyledAuthPanelInputContainer>
				<StyledAuthPanelInput
					type="text"
					id="username"
					name="username"
					label="username"
					autoComplete="username"
					value={username}
					onChange={(event) => {
						setUsername(event.target.value.trim());
					}}
					onBlur={(event) => {
						if (event.target.value === "") {
							setUsernameError(true);
						}
					}}
					onFocus={() => {
						setUsernameError(false);
						setPasswordError(false);
					}}
					active={Boolean(username)}
					placeholder={placeholderText}
					register
				/>
			</StyledAuthPanelInputContainer>
			{usernameError && (
				<ErrorMessage>
					{t("landing:login:loginEmptyError")}
				</ErrorMessage>
			)}
		</Block>
	);
};

// #####################################################

export default UsernameInput;

// #####################################################
